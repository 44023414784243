import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import {
  CodDashboardDetailCourier,
  DashboardConsoleData,
  DashboardData,
  DashboardDetailList,
  DashboardDetailListData,
  DataCorporateDashboard,
  DetailCourierPerStatusCodDashboardData,
  ListCourierPartnerCodDashboard,
  QueryParamDashboardToDetail,
  SummaryCodDashboardData,
  SummaryType
} from "@/domain/entities/Dashboard";
import { Pagination } from "@/domain/entities/Pagination";
import { container } from "tsyringe";
import { DashboardPresenter } from "../presenters/DashboardPresenter";
import {
  CutOffConsoleOutgoingSttData,
  dashboardConsoleData
} from "../views/dashboard/component/module/card-data";
import router from "../router";
import { ReportController } from "./ReportController";
import {
  CodDashboardCourierDetailRequest,
  CodDashboardRequest,
  CorporateDashboardRequest,
  CourierDailyDepositRequest,
  DashboardConsoleRequest,
  DashboardDetailListRequest,
  DashboardDownloadRequest,
  DownloadSummaryCodRequest
} from "@/data/payload/api/DashboardRequests";
import { IS_SENDER_ACCOUNT } from "@/app/infrastructures/misc/Constants";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { MainAppController } from "./MainAppController";
import {
  RequestList,
  ResponsePayloadV2
} from "@/domain/entities/ResponsePayload";
import moment from "moment";
import { AccountController } from "@/app/ui/controllers/AccountController";

export interface DashboardState {
  isLoading: boolean;
  error: boolean;
  errorCause: string;
}
@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "dashboard"
})
class DashboardStore extends VuexModule implements DashboardState {
  public isLoading = false;
  public isLoadingCutOff = false;
  public isLoadingPosFollowUp = false;
  public error = false;
  public errorCause = "";
  public dashboardDetailData: DashboardData = new DashboardData();
  public dashboardConsoleData: Array<DashboardData> = [];
  public dashboardDetailList = new DashboardDetailList({
    pagination: new Pagination(1, 20, 20),
    data: [] as DashboardDetailListData[]
  });
  public posFollowUpData = new ResponsePayloadV2();
  public sorting = { sortBy: "desc", orderBy: "total_on_process" };
  type = "";
  activeMenuTabs = {
    main: "all-stt",
    child: "outgoing"
  };
  public cutOffConsoleOutgoingSttData: Array<any> = [];
  public lastSyncCODDashboard = "";

  @Mutation
  typeForStatus(type: string) {
    if (type.includes("incoming")) this.type = "incoming";
    else if (type.includes("outgoing")) this.type = "outgoing";
    else this.type = type;
  }

  @Action
  public async getDashboardConsoleData(params: DashboardConsoleRequest) {
    const { name } = router.currentRoute.value;
    if (name === "dashboard" && this.activeMenuTabs.main === "all-stt") {
      this.onChangeDashboardConsoleData({
        data: new DashboardData({
          status: params.lastStatus,
          type: params.type,
          loading: true
        }),
        key: ["loading"]
      });
      this.typeForStatus(params.type);
      const presenter = await container.resolve(DashboardPresenter);
      presenter
        .getConsoleData(
          new DashboardConsoleRequest({
            cache: params.cache,
            type: this.type,
            lastStatus: params.lastStatus
          })
        )
        .then((res: DashboardConsoleData) => {
          this.onChangeDashboardConsoleData({
            data: new DashboardData({
              status: params.lastStatus,
              type: params.type,
              totalStt: res.total,
              loading: false,
              error: false
            }),
            key: ["totalStt", "loading", "error"]
          });
        })
        .catch(() => {
          if (params.attempt < 2) {
            new Promise(resolve =>
              setTimeout(() => {
                resolve(
                  this.getDashboardConsoleData(
                    new DashboardConsoleRequest({
                      attempt: params.attempt + 1,
                      lastStatus: params.lastStatus,
                      type: params.type
                    })
                  )
                );
              }, 500)
            );
          } else {
            this.onChangeDashboardConsoleData({
              data: new DashboardData({
                status: params.lastStatus,
                type: params.type,
                error: true,
                loading: false
              }),
              key: ["error", "loading"]
            });
          }
        });
    }
  }

  @Action
  public getCutOffConsoleOutgoingStt() {
    this.setErrorCause("");
    this.setLoadingCutOff(true);
    const presenter = container.resolve(DashboardPresenter);
    return presenter
      .getCutOffConsoleOutgoingStt()
      .then(res => {
        this.setCutOffConsoleOutgoingStt(res);
        this.setErrorCause("");
        return true;
      })
      .catch(err => {
        console.log({ err });
        this.setErrorCause(err.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoadingCutOff(false);
      });
  }

  @Action
  public async getListPosFollowUpDashboard() {
    this.setLoadingPosFollowUp(true);
    const presenter = container.resolve(DashboardPresenter);
    return presenter
      .getListPosFollowUpDashboard(
        new RequestList({
          cache: true,
          isTotalData: true,
          page: this.posFollowUpData.pagination.page,
          limit: this.posFollowUpData.pagination.limit,
          sortBy: this.sorting.sortBy,
          orderBy: this.sorting.orderBy
        })
      )
      .then((res: ResponsePayloadV2) => {
        this.setPosFollowUpList(res);
        this.setErrorCause("");
        this.setError(false);
      })
      .catch(err => {
        this.setError(true);
        this.setErrorCause(err.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoadingPosFollowUp(false);
      });
  }

  @Action
  public async getDashboarDetailList(params: DashboardDetailListRequest) {
    this.setLoading(true);
    if (!params.isOutside) {
      MainAppController.showLoading();
    }
    const presenter = container.resolve(DashboardPresenter);
    return presenter
      .getDashboardDetailList(params)
      .then(res => {
        this.setDashboarDetailList(res);
        this.setErrorCause("");
        this.setError(false);
      })
      .catch(err => {
        this.setError(true);
        this.setErrorCause(err.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
        MainAppController.closeLoading();
      });
  }

  @Action
  public fetchDashboardDetailList(params: {
    status: string;
    type: string;
    tab: string;
    posCode?: string;
    isOutside?: true;
  }) {
    this.typeForStatus(this.dashboardDetailData.type);
    this.getDashboarDetailList(
      new DashboardDetailListRequest({
        type: this.type || params.type,
        lastStatus: this.dashboardDetailData.status || params.status,
        page: this.dashboardDetailList.pagination.page,
        limit: this.dashboardDetailList.pagination.limit,
        isTotalData: true,
        tab: params.tab,
        posCode: params.posCode,
        status: params.status,
        isOutside: params.isOutside
      })
    );
  }

  @Action
  public getSummaryDownload(params: {
    type: string;
    filter: DownloadSummaryCodRequest;
  }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(DashboardPresenter);
    presenter
      .getSummaryDownload(params.type, params.filter)
      .then(() => {
        ReportController.onGetListDownloadReport();
        ReportController.setOpenDownloadRequest(true);
      })
      .catch((err: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Download Gagal!", () =>
            this.getSummaryDownload(params)
          )
        );
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public getReferralAffiliate() {
    const presenter = container.resolve(DashboardPresenter);
    return presenter.getReferralAffiliate();
  }

  @Mutation
  private setLoading(value: boolean) {
    this.isLoading = value;
  }

  @Mutation
  private setLoadingCutOff(value: boolean) {
    this.isLoadingCutOff = value;
  }

  @Mutation
  private setLoadingPosFollowUp(value: boolean) {
    this.isLoadingPosFollowUp = value;
  }

  @Mutation
  private setError(boolean: boolean) {
    this.error = boolean;
  }

  @Mutation
  private setErrorCause(str: string) {
    this.errorCause = str;
  }

  @Mutation
  public setDashboardConsoleData() {
    this.dashboardConsoleData = dashboardConsoleData.map(item => ({
      ...item,
      loading: true
    }));
  }

  @Mutation
  private onChangeDashboardConsoleData(params: {
    data: DashboardData;
    key: Array<string>;
  }) {
    const tempData: any = params.data;

    this.dashboardConsoleData = this.dashboardConsoleData.map((item: any) => {
      if (
        item["status"] === params.data["status"] &&
        item["type"] === params.data["type"]
      ) {
        params.key?.map(key => (item[key] = tempData[key]));
        return item;
      }
      return item;
    });
  }

  @Mutation
  public onResetCutOffConsoleOutgoingStt() {
    this.cutOffConsoleOutgoingSttData = CutOffConsoleOutgoingSttData.map(
      item => ({
        ...item,
        totalStt: 0
      })
    );
  }

  @Mutation
  setCutOffConsoleOutgoingStt(data: any) {
    this.cutOffConsoleOutgoingSttData = CutOffConsoleOutgoingSttData.map(
      (item: any) => {
        const groupBy: any = data[item.type][item.section];
        let cycleStart = "";
        let cycleEnd = "";

        if (item.section.includes("cycle")) {
          cycleStart = groupBy[item.start];
          cycleEnd = groupBy[item.endKey];
        }

        return {
          ...item,
          totalStt: typeof groupBy === "number" ? groupBy : groupBy[item.key],
          start: cycleStart,
          end: cycleEnd
        };
      }
    );
  }

  @Mutation
  public onResetDashboardConsoleData() {
    this.dashboardConsoleData = [];
  }

  @Action
  onToDetail(cardData: DashboardData) {
    this.setDashboardDetailData({
      title: cardData?.title,
      detailTitle: cardData?.detailTitle,
      status: cardData?.status,
      total: cardData?.totalStt,
      description: cardData?.description,
      type: cardData?.type
    });
    const route = router.resolve({
      path: `/dashboard/detail`,
      query: {
        from: cardData?.endpoint || this.activeMenuTabs.main,
        title: cardData?.detailTitle + " " + cardData?.title,
        type: cardData?.type,
        status: cardData?.status,
        posCode: cardData?.posCode,
        fromOutside: cardData?.fromOutside
      }
    });
    this.activeMenuTabs.main === "all-stt"
      ? router.push("/dashboard/detail")
      : window.open(route.href);
    return true;
  }

  @Action
  async onDownloadXlsx(cardData: DashboardData) {
    const presenter = container.resolve(DashboardPresenter);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    this.typeForStatus(cardData.type);
    presenter
      .getDownloadDashboard(
        new DashboardDownloadRequest({
          type: this.type,
          lastStatus: cardData.status,
          tab: this.activeMenuTabs.main === "current-stt" ? "current-stt" : ""
        })
      )
      .then(() => {
        ReportController.onGetListDownloadReport();
        ReportController.setOpenDownloadRequest(true);
      })
      .catch(err => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Download Gagal!", () =>
            this.onDownloadXlsx(cardData)
          )
        );
      })
      .finally(() => MainAppController.closeLoading());
  }

  @Action
  public getDownloadDetailPOS(params: DashboardDetailListRequest) {
    MainAppController.showLoading();
    const presenter = container.resolve(DashboardPresenter);
    presenter
      .getDownloadDetailPOS(params)
      .then(() => MainAppController.closeLoading())
      .catch(err => {
        MainAppController.closeLoading();
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Download Gagal!", () =>
            this.getDownloadDetailPOS(params)
          )
        );
      });
  }

  @Action
  public async getLastSyncCOD() {
    const presenter = container.resolve(DashboardPresenter);
    try {
      return await presenter.getSummaryCodDashboardData(
        "/last-sync/cod",
        new CodDashboardRequest()
      );
    } catch (e) {
      return new SummaryCodDashboardData();
    }
  }

  @Mutation
  setLastSycnCODDashboard(lastSync: string) {
    this.lastSyncCODDashboard = lastSync;
  }
  @Mutation
  setDashboardDetailData(val: any) {
    this.dashboardDetailData = val;
  }

  @Mutation
  initTable() {
    this.dashboardDetailList.pagination.page = 1;
    this.posFollowUpData.pagination.page = 1;
    this.sorting = { sortBy: "desc", orderBy: "total_on_process" };
  }

  @Mutation
  setResetDashboarDetailList() {
    this.dashboardDetailList = new DashboardDetailList();
  }

  @Mutation
  setDashboarDetailList(val: DashboardDetailList) {
    this.dashboardDetailList = val;
  }

  @Mutation
  setPosFollowUpList(val: ResponsePayloadV2) {
    this.posFollowUpData = val;
  }

  @Mutation
  setSorting(params: { sortBy: string; orderBy: string }) {
    this.sorting = params;
  }

  @Mutation
  setActiveMenuTabs(params: { main?: string; child?: string }) {
    this.activeMenuTabs = {
      main: params.main || this.activeMenuTabs.main,
      child: params.child || this.activeMenuTabs.child
    };
  }

  // cod dashboard
  filterDateCodDashboard = new Date();
  @Mutation
  setFilterDateCodDashboard(value: Date) {
    this.filterDateCodDashboard = value;
  }
  searchListCourierPartnerCodDashboard = "";
  @Mutation
  setSearchListCourierPartnerCodDashboard(value: string) {
    this.searchListCourierPartnerCodDashboard = value;
  }

  loadingListCourierPartnerCodDashboard = false;
  @Mutation
  setLoadingListCourierPartnerCodDashboard(val: boolean) {
    this.loadingListCourierPartnerCodDashboard = val;
  }
  errorListCourierPartnerCodDashboard = "";
  @Mutation
  setErrorListCourierPartnerCodDashboard(val: string) {
    this.errorListCourierPartnerCodDashboard = val;
  }
  listCourierPartnerCodDashboard = new ListCourierPartnerCodDashboard();
  @Mutation
  setListCourierPartnerCodDashboard(val: ListCourierPartnerCodDashboard) {
    this.listCourierPartnerCodDashboard = val;
  }
  @Mutation
  addDatalistCourierPartnerCodDashboard(val: ListCourierPartnerCodDashboard) {
    this.listCourierPartnerCodDashboard.pagination = val.pagination;
    this.listCourierPartnerCodDashboard.data = [
      ...this.listCourierPartnerCodDashboard.data,
      ...val.data
    ];
  }
  @Action
  public getListCourierPartnerCodDashboard() {
    this.setLoadingListCourierPartnerCodDashboard(true);
    this.setErrorListCourierPartnerCodDashboard("");
    const accountLocationList =
      AccountController.accountData.account_location_list;
    const params = new CodDashboardRequest({
      page: this.listCourierPartnerCodDashboard.pagination.page,
      filterDate: moment(this.filterDateCodDashboard).format("YYYY-MM-DD"),
      search: this.searchListCourierPartnerCodDashboard,
      cityCode: accountLocationList?.length
        ? accountLocationList.map((list: any) => list.city_code).join(",")
        : ""
    });
    const presenter = container.resolve(DashboardPresenter);
    presenter
      .getListCourierPartnerCodDashboard(params)
      .then((resp: ListCourierPartnerCodDashboard) => {
        if (!resp.data.length && resp.pagination.page === 1) {
          this.setListCourierPartnerCodDashboard(
            new ListCourierPartnerCodDashboard()
          );
          this.setErrorListCourierPartnerCodDashboard("notfound");
        } else {
          this.addDatalistCourierPartnerCodDashboard(resp);
        }
      })
      .catch(err => {
        this.setListCourierPartnerCodDashboard(
          new ListCourierPartnerCodDashboard()
        );
        this.setErrorListCourierPartnerCodDashboard(
          err.response ? "server" : "internet"
        );
      })
      .finally(() => {
        this.setLoadingListCourierPartnerCodDashboard(false);
      });
  }
  detailCourierPartnerCodDashboard = new CodDashboardDetailCourier({
    courierPhone: "",
    courierName: "",
    courierId: 0,
    courierPartnerName: "",
    courierPartnerPhone: "",
    courierPartnerContactPhone: "",
    totalStt: 0,
    totalSttPod: 0,
    totalCollected: 0,
    totalOutstanding: 0,
    totalSttDexCollected: 0,
    totalSttDexOutstanding: 0,
    totalSttCodrejCollected: 0,
    totalSttCodrejOutstanding: 0,
    totalSttDel: 0,
    performanceSameDay: "",
    percentageSameDay: "",
    performanceDifferentDay: "",
    percentageDifferentDay: ""
  });
  phoneNumberCourier = "";
  @Mutation
  setDetailCourierPartnerCodDashboard(val: CodDashboardDetailCourier) {
    this.detailCourierPartnerCodDashboard = val;
  }
  @Mutation
  setPhoneNumberCourier(val: any) {
    this.phoneNumberCourier = val;
  }

  @Action
  public getDetailCourierPartnerCodDashboard(summary: { type: any }) {
    this.setLoadingListCourierPartnerCodDashboard(true);
    MainAppController.showLoading();
    const params = new CodDashboardCourierDetailRequest({
      type: summary.type,
      filterDate: moment(this.filterDateCodDashboard).format("YYYY-MM-DD"),
      driverPhone: this.phoneNumberCourier,
      picPhone: this.phoneNumberCourier
    });
    const presenter = container.resolve(DashboardPresenter);
    presenter
      .getDetailCourierData(params)
      .then((resp: CodDashboardDetailCourier) => {
        this.setDetailCourierPartnerCodDashboard(resp);
      })
      .catch(err => {
        this.setErrorListCourierPartnerCodDashboard(
          err.response ? "server" : "internet"
        );
      })
      .finally(() => {
        this.setLoadingListCourierPartnerCodDashboard(false);
        MainAppController.closeLoading();
      });
  }
  @Action
  public fetchDetailCourierPartnerCodDashboard(
    params: CodDashboardCourierDetailRequest
  ) {
    const presenter = container.resolve(DashboardPresenter);
    return presenter.getDetailCourierData(params);
  }
  @Action
  public postCourierDailyDeposit(summary: {
    type: any;
    payload: CourierDailyDepositRequest;
  }) {
    const presenter = container.resolve(DashboardPresenter);
    return presenter.postCourierDailyDeposit(summary.type, summary.payload);
  }

  @Action
  public getSummaryCodDashboardData(summary: SummaryType) {
    const accountLocationList =
      AccountController.accountData.account_location_list;
    const params = new CodDashboardRequest({
      sttCodStatus: summary.sttStatus.split("-")[0],
      deliveryCollectedStatus: summary.sttStatus.split("-")[1] ?? "",
      filterDate: moment(this.filterDateCodDashboard).format("YYYY-MM-DD"),
      cityCode: accountLocationList?.length
        ? accountLocationList.map((list: any) => list.city_code).join(",")
        : "",
      paymentMethod: summary.paymentMethod
    });
    const pathType = summary.type === "courier" ? "cod" : "pic";
    const pathEndpoint =
      summary.sttStatus.toLowerCase() === "total"
        ? `/total/${pathType}`
        : `/summary/${pathType}`;
    const presenter = container.resolve(DashboardPresenter);
    return presenter
      .getSummaryCodDashboardData(pathEndpoint, params)
      .then((resp: SummaryCodDashboardData) => {
        return new SummaryCodDashboardData({
          ...resp,
          sttStatus: summary.sttStatus,
          paymentMethod: summary.paymentMethod
        });
      })
      .catch(() => {
        return new SummaryCodDashboardData({
          sttStatus: params.sttCodStatus,
          paymentMethod: summary.paymentMethod
        });
      });
  }
  sttDataCourier: DetailCourierPerStatusCodDashboardData[] = [];
  @Mutation
  setSttDataCourierData(val: DetailCourierPerStatusCodDashboardData[]) {
    this.sttDataCourier = val;
  }
  @Mutation
  addSttDataCourierData(val: DetailCourierPerStatusCodDashboardData[]) {
    this.sttDataCourier = [...this.sttDataCourier, ...val];
  }

  @Action
  public getDetailCourierPerStatusCodDashboardData(summary: {
    type: any;
    sttStatus: string;
  }) {
    const params = new CodDashboardCourierDetailRequest({
      type: summary.type,
      status: summary.sttStatus,
      filterDate: moment(this.filterDateCodDashboard).format("YYYY-MM-DD"),
      driverPhone: this.phoneNumberCourier,
      picPhone: this.phoneNumberCourier
    });
    const presenter = container.resolve(DashboardPresenter);
    return presenter
      .getDetailCourierPerStatusCodDashboardData(params)
      .then((resp: DetailCourierPerStatusCodDashboardData[]) => {
        this.addSttDataCourierData(resp);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  @Action
  public async getCourierDetailDownload(payload: {
    driverPhone: any;
    isPic: boolean;
  }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const params = new CodDashboardCourierDetailRequest({
      filterDate: moment(this.filterDateCodDashboard).format("YYYY-MM-DD")
    });
    const presenter = container.resolve(DashboardPresenter);
    return presenter
      .getDetailCourierDownloadCodDashboardData(
        payload.driverPhone,
        params,
        payload.isPic
      )
      .then(() => {
        ReportController.onGetListDownloadReport();
        ReportController.setOpenDownloadRequest(true);
      })
      .catch((err: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Download Gagal!", () =>
            this.getCourierDetailDownload(payload.driverPhone)
          )
        );
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  // corporate / sender dashboard
  filterCorporateDashboard: any = {
    startDate: "",
    endDate: "",
    product: {
      name: "Semua Layanan",
      value: "all"
    },
    deliveryType: {
      name: "Semua Pengiriman",
      value: "all"
    },
    branch: { id: 0, name: "Semua Cabang", value: "all" }
  };
  @Mutation
  resetFilterCorporateDashboard() {
    this.filterCorporateDashboard = {
      startDate: "",
      endDate: "",
      product: {
        name: "Semua Layanan",
        value: "all"
      },
      deliveryType: {
        name: "Semua Pengiriman",
        value: "all"
      },
      branch: { id: 0, name: "Semua Cabang", value: "all" }
    };
  }

  @Action
  public async getCorporateDashboardDeliveryData(params: {
    path: string;
    sttCategory: string;
    deliveryType?: string;
    cache?: boolean;
  }) {
    const requestParam = new CorporateDashboardRequest({
      cache: params.cache,
      startDate: moment(this.filterCorporateDashboard.startDate).format(
        "YYYY-MM-DD"
      ),
      endDate: moment(this.filterCorporateDashboard.endDate).format(
        "YYYY-MM-DD"
      ),
      productType: this.filterCorporateDashboard.product.value,
      deliveryType: params.deliveryType
        ? params.deliveryType
        : this.filterCorporateDashboard.deliveryType.value,
      bookedForId:
        !IS_SENDER_ACCOUNT && !AccountController.accountData.isPosAccount
          ? this.filterCorporateDashboard.branch.value
          : "",
      sttCategory: params.sttCategory
    });
    const presenter = container.resolve(DashboardPresenter);
    try {
      return await presenter.getCorporateDashboardData(
        params.path,
        requestParam
      );
    } catch (e) {
      return new DataCorporateDashboard();
    }
  }

  @Action
  public async getCorporateDashboardLastSyncData() {
    const presenter = container.resolve(DashboardPresenter);
    try {
      return await presenter.getCorporateDashboardData(
        "/sync",
        new CorporateDashboardRequest()
      );
    } catch (e) {
      return new DataCorporateDashboard();
    }
  }

  @Action
  public async getCorporateDashboardDetailAreaBarChart(params: {
    path: string;
  }) {
    const requestParam = new CorporateDashboardRequest({
      startDate: moment(this.filterCorporateDashboard.startDate).format(
        "YYYY-MM-DD"
      ),
      endDate: moment(this.filterCorporateDashboard.endDate).format(
        "YYYY-MM-DD"
      ),
      productType: this.filterCorporateDashboard.product.value,
      deliveryType: this.filterCorporateDashboard.deliveryType.value,
      bookedForId:
        !IS_SENDER_ACCOUNT && !AccountController.accountData.isPosAccount
          ? this.filterCorporateDashboard.branch.value
          : "",
      cache: true
    });
    const presenter = container.resolve(DashboardPresenter);
    try {
      return await presenter.getCorporateDashboardData(
        params.path,
        requestParam
      );
    } catch (e) {
      return new DataCorporateDashboard();
    }
  }

  @Action
  private async parsingDashboardDetailQueryParam(dashboardInfo: string) {
    const query = new QueryParamDashboardToDetail({
      startDate: moment(this.filterCorporateDashboard.startDate).format(
        "YYYY-MM-DD"
      ),
      endDate: moment(this.filterCorporateDashboard.endDate).format(
        "YYYY-MM-DD"
      ),
      productType:
        this.filterCorporateDashboard.product.value != "all"
          ? this.filterCorporateDashboard.product.value
          : "",
      deliveryType: this.filterCorporateDashboard.deliveryType.value
    });
    const pathPengirimanAktif = IS_SENDER_ACCOUNT
      ? "/lacak-pengiriman/pengiriman-aktif"
      : "/shipment/lacak-pengiriman/pengiriman-aktif";
    const pathRiwayatPengiriman = IS_SENDER_ACCOUNT
      ? "/lacak-pengiriman/riwayat-pengiriman"
      : "/shipment/lacak-pengiriman/riwayat-pengiriman";
    const pathClaim = "/klaim";
    const pathPerformanceDelivery = "/dashboard/performa-pengiriman";
    switch (dashboardInfo) {
      case "status-IN_PROGRESS": {
        query.path = pathPengirimanAktif;
        break;
      }
      case "status-SUCCESS": {
        query.path = pathRiwayatPengiriman;
        query.deliveryStatus = "POD,STT ADJUSTED POD";
        break;
      }
      case "status-FAILED": {
        query.path = pathRiwayatPengiriman;
        query.deliveryStatus = "CNX,SCRAP,MISSING,DAMAGE,CODREJ";
        break;
      }
      case "status-RETURN": {
        query.path = pathRiwayatPengiriman;
        query.deliveryStatus = "RTS,RTSHQ";
        break;
      }
      case "cod-IN_PROGRESS": {
        query.path = pathPengirimanAktif;
        query.deliveryType = "cod";
        break;
      }
      case "cod-SUCCESS": {
        query.path = pathRiwayatPengiriman;
        query.deliveryStatus = "POD,STT ADJUSTED POD";
        query.deliveryType = "cod";
        break;
      }
      case "cod-CANCEL": {
        query.path = pathRiwayatPengiriman;
        query.deliveryStatus = "CNX,SCRAP,MISSING,DAMAGE,CODREJ";
        query.deliveryType = "cod";
        break;
      }
      case "claim-total": {
        query.path = pathClaim;
        break;
      }
      case "claim-on_request": {
        query.path = pathClaim;
        query.status = "submitted";
        break;
      }
      case "claim-on_approved": {
        query.path = pathClaim;
        query.status = "approved";
        break;
      }
      case "claim-on_rejected": {
        query.path = pathClaim;
        query.status = "rejected";
        break;
      }
      case "claim-on_done": {
        query.path = pathClaim;
        query.status = "finished";
        break;
      }
      case "performance-summary": {
        query.path = pathPerformanceDelivery;
        query.status = "all";
        break;
      }
      case "performance-late": {
        query.path = pathPerformanceDelivery;
        query.status = "late_sla";
        break;
      }
      case "performance-onTime": {
        query.path = pathPerformanceDelivery;
        query.status = "meet_sla";
        break;
      }
      default:
        break;
    }
    return query.toFullPath();
  }
  @Action
  public async openNewPageFromDashboardCorporate(dashboardInfo: string) {
    const pathDetailDashboard = await this.parsingDashboardDetailQueryParam(
      dashboardInfo
    );
    window.open(pathDetailDashboard, "_blank");
  }
}

export const DashboardController = getModule(DashboardStore);
