import { PrintDataEntitas } from "@/domain/entities/Booking";
import jsPDF from "jspdf";
import { startCase } from "lodash";
import moment from "moment";

let translate: any;

export const ursaSla = (
  params: {
    doc: jsPDF | any;
    startX: number;
    startY: number;
    data: PrintDataEntitas;
  },
  estimateSla: string,
  coor: {
    startX: number;
    startY: number;
  },
  translate: any
) => {
  // URSA CODE
  params.doc.setFont("Montserrat", "bold");
  params.doc.setTextColor("#333333");
  params.doc.setFontSize(20);
  params.doc.text(
    params.data.sttDistrictUrsaCode,
    coor.startX + 78,
    coor.startY + 29
  );

  const isInterpack = params.data.sttProductType === 'INTERPACK';

  if (isInterpack) {
    // CREATED AT
    params.doc.setFont("Montserrat", "bold");
    params.doc.setTextColor("#333333");
    params.doc.setFontSize(6);
    params.doc.text(startCase(translate("receipt.createdAtV2")), coor.startX + 78, coor.startY + 33);
    params.doc.text(":", coor.startX + 90, coor.startY + 33);
    params.doc.setFont("Montserrat", "normal");
    params.doc.text(
      `${moment(params.data.sttCreatedAt).format("DD/MM/YYYY")}`,
      coor.startX + 91.5,
      coor.startY + 33
    );

    // CREATED AT
    params.doc.setFont("Montserrat", "bold");
    params.doc.setTextColor("#333333");
    params.doc.setFontSize(6);
    params.doc.text(startCase(translate("receipt.estimation")), coor.startX + 78, coor.startY + 37);
    params.doc.text(":", coor.startX + 90, coor.startY + 37);
    params.doc.setFont("Montserrat", "normal");
    params.doc.text(
      moment(estimateSla).format("DD/MM/YYYY"),
      coor.startX + 91.5,
      coor.startY + 37
    );
  } else {
    // CREATED AT
    params.doc.setFont("Montserrat", "bold");
    params.doc.setTextColor("#333333");
    params.doc.setFontSize(6);
    params.doc.text(startCase(translate("receipt.createdAt")), coor.startX + 78, coor.startY + 33);
    params.doc.text(":", coor.startX + 87.5, coor.startY + 33);
    params.doc.setFont("Montserrat", "normal");
    params.doc.text(
      `${moment(params.data.sttCreatedAt).format("DD/MM/YYYY")}`,
      coor.startX + 89,
      coor.startY + 33
    );

    // CREATED AT
    params.doc.setFont("Montserrat", "bold");
    params.doc.setTextColor("#333333");
    params.doc.setFontSize(6);
    params.doc.text(startCase(translate("receipt.estimation")), coor.startX + 78, coor.startY + 37);
    params.doc.text(":", coor.startX + 87.5, coor.startY + 37);
    params.doc.setFont("Montserrat", "normal");
    params.doc.text(
      moment(estimateSla, "DD MMM YYYY").format("DD/MM/YYYY"),
      coor.startX + 89,
      coor.startY + 37
    );
  }
};
