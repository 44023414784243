/* eslint-disable @typescript-eslint/camelcase */
import jsPDF from "jspdf";
import {
  destinationRect,
  maskPhone,
  badWordsMasking,
  senderSection,
  vendorPickupDetail,
  estimationBound,
  productRect,
  dimensionRect,
  productRectMini,
  totalBiayaRect,
  convertQRCode,
  externalRefSection,
  abbreviationVendorCode,
  isCourierRecommendation,
  titleCase,
  isCodRetail,
  codDfodLabel,
  formatPrice
} from "../../module";
import uuid from "@/app/infrastructures/misc/common-library/Uuid";
import startCase from "lodash/startCase";
import dateToDateString from "@/app/infrastructures/misc/common-library/DateToDateString";
import removeDecimalWithoutRounding from "@/app/infrastructures/misc/common-library/RemoveDecimalWithoutRounding";
import { PrintDataEntitas } from "@/domain/entities/Booking";
import moment from "moment";
import { FlagsFeatureShipmentC1 } from "@/feature-flags/flags-shipment-c1";

export const getWaterMark = async (
  doc: jsPDF,
  params: {
    watermarkCod: boolean,
    isPad: boolean,
    watermarkDfod: boolean,
    productType: string,
  },
  coor: {
    startX: number,
    startY: number
  }
) => {
  if (params.watermarkCod && !params.watermarkDfod && params.isPad && params.productType !== "ONEPACK") {
    const watermark = await import(
      "@/app/ui/assets/images/watermark/bg_cod_pad.png"
    );
    return doc.addImage(
      watermark.default,
      "PNG",
      coor.startX,
      coor.startY,
      104,
      144,
      "bg_cod_pad",
      "FAST"
    );
  } else if (params.watermarkCod && !params.watermarkDfod && !params.isPad && params.productType !== "ONEPACK") {
    const watermark = await import(
      "@/app/ui/assets/images/watermark/bg_cod.png"
    );
    return doc.addImage(
      watermark.default,
      "PNG",
      coor.startX,
      coor.startY,
      104,
      144,
      "bg_cod",
      "FAST"
    );
  } else if (params.watermarkCod && !params.watermarkDfod && !params.isPad && params.productType === "ONEPACK") {
    const watermark = await import(
      "@/app/ui/assets/images/watermark/bg_onepack_cod.png"
    );
    return doc.addImage(
      watermark.default,
      "PNG",
      coor.startX,
      coor.startY,
      104,
      144,
      "bg_onepack_cod",
      "FAST"
    );
  } else if (params.watermarkCod && params.watermarkDfod  && !params.isPad && params.productType === "ONEPACK") {
    const watermark = await import(
      "@/app/ui/assets/images/watermark/bg_onepack_dfod.png"
    );
    return doc.addImage(
      watermark.default,
      "PNG",
      coor.startX,
      coor.startY,
      104,
      144,
      "bg_onepack_dfod",
      "FAST"
    );
  } else if (!params.watermarkCod && !params.watermarkDfod  && params.isPad && params.productType === "ONEPACK") {
    const watermark = await import(
      "@/app/ui/assets/images/watermark/bg_onepack_pad.png"
    );
    return doc.addImage(
      watermark.default,
      "PNG",
      coor.startX,
      coor.startY,
      104,
      144,
      "bg_onepack_pad",
      "FAST"
    );
  } else if (!params.watermarkCod && !params.watermarkDfod  && !params.isPad && params.productType === "ONEPACK") {
    const watermark = await import(
      "@/app/ui/assets/images/watermark/bg_onepack.png"
    );
    return doc.addImage(
      watermark.default,
      "PNG",
      coor.startX,
      coor.startY,
      104,
      144,
      "bg_onepack",
      "FAST"
    );
  } else if (!params.watermarkCod && !params.watermarkDfod  && params.isPad && params.productType !== "ONEPACK") {
    const watermark = await import(
      "@/app/ui/assets/images/watermark/bg_pad.png"
    );
    return doc.addImage(
      watermark.default,
      "PNG",
      coor.startX,
      coor.startY,
      104,
      144,
      "bg_pad",
      "FAST"
    );
  }

  if (params.watermarkCod && params.watermarkDfod) {
    const watermark = await import(
      "@/app/ui/assets/images/watermark/bg_dfod.png"
    );
    doc.addImage(
      watermark.default,
      "PNG",
      coor.startX,
      coor.startY,
      104,
      144,
      "bg_dfod",
      "FAST"
    );
  }

}

export const notLabeled = (isCod: boolean, isPad: boolean, isDfod: boolean) => {
  return !isCod && !isPad && !isDfod;
}

export const headerSection = (
  doc: jsPDF | any,
  coor: {
    startX: number,
    startY: number,
  },
  data: PrintDataEntitas,
  params: {
    logo: string,
    logoAspectRatio: number,
    ursaCode: string,
    barcode: string,
    barcodeValue: string,
    language: string,
    estimateSla: string,
    translate: Function
  }
) => {
  doc.addImage(params.logo, "PNG", coor.startX + 5, coor.startY + 5, 32, params.logoAspectRatio * 32, "logo", "FAST", 0);

  // LINE TOP
  doc.setLineDash();
  doc.setLineWidth(0.2);
  doc.line(coor.startX, coor.startY + 14, coor.startX + 105, coor.startY + 14);

  // ORIGIN DESTINATION
  doc.setFont("helvetica", "bold");
  doc.setTextColor("#333333");
  doc.setFontSize(16);

  doc.text(`${data.sttOriginCityId} - ${data.sttDestinationCityId}`, coor.startX + destinationRect(params.ursaCode).destinationLeft, coor.startY + 12, { align: "right" });
  doc.text(`${data.sttOriginCityId} - ${data.sttDestinationCityId}`, coor.startX + destinationRect(params.ursaCode).destinationBoldLeft, coor.startY + 12, { align: "right" });

  // VERTICAL LINE
  doc.setLineWidth(0.5);
  doc.line(coor.startX + destinationRect(params.ursaCode).verticalLineLeft, coor.startY + 7, coor.startX + destinationRect(params.ursaCode).verticalLineLeft, coor.startY + 13);

  // URSA
  doc.setFont("helvetica", "normal");
  doc.setTextColor("#333333");
  doc.setFontSize(16);
  doc.text(params.ursaCode, coor.startX + 100, coor.startY + 12, {
    align: "right"
  });

  // GENESIS BARCODE
  doc.addImage(params.barcode, "PNG", coor.startX + 3, coor.startY + 19, 80, 15, uuid(), "FAST", 0);
  doc.setFont("helvetica", "bold");
  doc.setTextColor("#333333");
  doc.setFontSize(8);
  doc.text(params.barcodeValue, coor.startX + 5, coor.startY + 18);
  doc.text(params.barcodeValue, coor.startX + 5.1, coor.startY + 18);

  // DESTINATION DISTRICT
  doc.setFont("helvetica", "normal");
  doc.setTextColor("#333333");
  doc.setFontSize(8);
  const sttDestinationDistrictNameData = data.sttDestinationDistrictName.length >= 45 ? `${data.sttDestinationDistrictName.substring(0, 45)}...` : data.sttDestinationDistrictName;
  doc.text(titleCase(sttDestinationDistrictNameData), coor.startX + 100, coor.startY + 18, { align: "right" });

  // CREATED DATE
  doc.setFont("helvetica", "normal");
  doc.setTextColor("#333333");
  doc.setFontSize(6);
  doc.text(`${startCase(params.translate("receipt.createdAt"))} :\n${dateToDateString(data.sttCreatedAt, true, false, params.language)}\n\n${startCase(params.translate("receipt.estimation"))} :\n${params.estimateSla}`, coor.startX + 100, coor.startY + 21.8, { align: "right" });
}

export const originDestinationSection = (
  doc: jsPDF | any,
  coor: {
    startX: number,
    startY: number,
  },
  data: PrintDataEntitas,
  params: {
    translate: Function
  }
) => {
  // SHIPER CONSIGNE
  doc.setLineWidth(0.2);
  doc.setFillColor(255, 255, 255);
  doc.roundedRect(coor.startX + 5, coor.startY + 35, 95, 32, 1, 1);

  // SHIPER LABEL
  doc.setFont("helvetica", "normal");
  doc.setTextColor("#333333");
  doc.setFontSize(6);
  doc.text(startCase(params.translate("receipt.shipper")), coor.startX + 6, coor.startY + 38);
  doc.text(":", coor.startX + 18, coor.startY + 38);
  doc.setFont("helvetica", "bold");

  const senderAddress = data.sttOriginCityName;
  const sttSenderNameData = data.sttSenderName.length > 44 ? `${data.sttSenderName.substring(0, 44)}...` : data.sttSenderName;
  doc.text(`${badWordsMasking(sttSenderNameData).toUpperCase()}, ${maskPhone(data.sttSenderPhone)}\n${senderSection(senderAddress).senderAddress.toUpperCase()}`, coor.startX + 19, coor.startY + 38);

  // CONSIGNE LABEL
  doc.setFont("helvetica", "normal");
  doc.setTextColor("#333333");
  doc.setFontSize(6);
  doc.text(startCase(params.translate("receipt.receiver")), coor.startX + 6, coor.startY + 43);
  doc.text(":", coor.startX + 18, coor.startY + 43);
  doc.setFont("helvetica", "bold");
  const sttRecipientNameData = data.sttRecipientName.length > 44 ? `${data.sttRecipientName.substring(0, 44)}...` : data.sttRecipientName; doc.text(`${badWordsMasking(sttRecipientNameData).toUpperCase()}, ${data.sttRecipientPhone}`, coor.startX + 19, coor.startY + 43);
  const postalCode =
    data.sttProductType.toLocaleLowerCase() === "interpack"
      ? `, ${data.sttPostalCode}`
      : "";
  doc.text(
    doc.splitTextToSize(
      `${data.sttRecipientAddress.toUpperCase()} ${postalCode}`,
      80
    ),
    coor.startX + 19,
    coor.startY + 46
  );

  // ADDRESS TYPE
  if (data.sttRecipientAddressType === "office") {
    doc.setLineWidth(0.3);
    doc.setFillColor("#333333");
    doc.setDrawColor("#333333");
    doc.roundedRect(coor.startX + 6, coor.startY + 46, 10, 3, 0.5, 0.5, "FD");
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#FFFFFF");
    doc.setFontSize(6);
    doc.text(startCase(params.translate("receipt.office")), coor.startX + 7.5, coor.startY + 48);
  }
}

export const commoditySection = async (
  doc: jsPDF | any,
  coor: { startX: number, startY: number, },
  data: PrintDataEntitas,
  params: { language: string, isPad: boolean, isCod: boolean, isDfod: boolean, externalRefValue: string, vendorRefCodeValue: string, vendorRefCode: string }
) => {
  // COMMODITY
  doc.setFont("helvetica", "bold");
  doc.setTextColor("#333333");
  doc.setFontSize(6);
  let ellipsisCommodityName = data.sttCommodityName;
  if (params.language === "en") {
    ellipsisCommodityName = data.sttCommodityDescription;
  }
  ellipsisCommodityName = ellipsisCommodityName.length > 60 ? `${ellipsisCommodityName.substring(0, 60)}...` : ellipsisCommodityName;
  doc.text(doc.splitTextToSize(titleCase(ellipsisCommodityName), 60), coor.startX + 6, coor.startY + 63.5);

  // LABEL BLOCK
  let xPointSymbol = coor.startX + 90.8;
  let xBgPointSymbol = coor.startX + 89;

  if (data.sttIsDo) {
    xPointSymbol += 2;
    xBgPointSymbol += 2;
    doc.setLineWidth(0.3);
    doc.setFillColor("#333333");
    doc.setDrawColor("#333333");
    doc.roundedRect(xBgPointSymbol, coor.startY + 59, 8, 6, 0.5, 0.5, "FD");
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#FFFFFF");
    doc.setFontSize(9);
    doc.text("DO", xPointSymbol, coor.startY + 63);
    xPointSymbol -= 10.8;
    xBgPointSymbol -= 11;
  }

  if (params.isPad) {
    doc.setLineWidth(0.3);
    doc.setFillColor("#333333");
    doc.setDrawColor("#333333");
    doc.roundedRect(xBgPointSymbol, coor.startY + 59, 10, 6, 0.5, 0.5, "FD");
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#FFFFFF");
    doc.setFontSize(9);
    doc.text("PAD", xPointSymbol, coor.startY + 63);
    xPointSymbol -= 11;
    xBgPointSymbol -= 11;
  }

  if (params.isCod || params.isDfod) {
    xPointSymbol -= params.isDfod ? 1.1 : 0.2;
    doc.setLineWidth(0.3);
    doc.setFillColor("#333333");
    doc.setDrawColor("#333333");
    doc.roundedRect(xBgPointSymbol, coor.startY + 59, 10, 6, 0.5, 0.5, "FD");
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#FFFFFF");
    doc.setFontSize(9);
    doc.text(params.isDfod ? "DFOD" : "COD", xPointSymbol, coor.startY + 63);
    xPointSymbol -= params.isDfod ? 10.7 - 0.9 : 10.7;
    xBgPointSymbol -= 11;
  }

  if (data.sttIsRts) {
    doc.setLineWidth(0.3);
    doc.setFillColor("#333333");
    doc.setDrawColor("#333333");
    doc.roundedRect(xBgPointSymbol, coor.startY + 59, 10, 6, 0.5, 0.5, "FD");
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#FFFFFF");
    doc.setFontSize(9);
    doc.text("RTS", xPointSymbol, coor.startY + 63);
    xPointSymbol -= 10.7;
    xBgPointSymbol -= 11;
  }

  if (data.sttIsRerouteMisbooking) {
    doc.setLineWidth(0.3);
    doc.setFillColor("#333333");
    doc.setDrawColor("#333333");
    doc.roundedRect(xBgPointSymbol, coor.startY + 59, 8, 6, 0.5, 0.5, "FD");
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#FFFFFF");
    doc.setFontSize(9);
    doc.text("RB", xPointSymbol, coor.startY + 63);
    xPointSymbol -= 11;
    xBgPointSymbol -= 11;
  }

  if (data.sttIsRerouteMisroute) {
    doc.setLineWidth(0.3);
    doc.setFillColor("#333333");
    doc.setDrawColor("#333333");
    doc.roundedRect(xBgPointSymbol, coor.startY + 59, 8, 6, 0.5, 0.5, "FD");
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#FFFFFF");
    doc.setFontSize(9);
    doc.text("RR", xPointSymbol, coor.startY + 63);
  }
}

export const referencesRect = (isReverseJourneyAfterCancel: boolean, isDangerousGoods: boolean) => {
  let xRect = 69;
  let xText = 70.8;

  if (!isReverseJourneyAfterCancel) {
    xRect += 11;
    xText += 11;
  }

  if (!isDangerousGoods) {
    xRect += 11;
    xText += 11;
  }

  return {
    xRect, xText
  }
}

export const referencesSection = async (
  doc: jsPDF | any,
  coor: { startX: number, startY: number, },
  data: PrintDataEntitas,
  params: { externalRefValue: string, vendorRefCodeValue: string, vendorRefCode: string }
) => {
  const vendorName = vendorPickupDetail(data.sttVendorName, data.sttDistrictType);
  if (vendorName) {
    const { xRect, xText } = referencesRect(data.isReverseJourneyAfterCancel, data.isDangerousGoods);
    doc.setLineWidth(0.3);
    doc.setFillColor("#333333");
    doc.setDrawColor("#333333");
    doc.roundedRect(coor.startX + xRect, coor.startY + 70, 8, 6, 0.5, 0.5, "FD");
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#FFFFFF");
    doc.setFontSize(9);
    doc.text(vendorName, coor.startX + xText, coor.startY + 74);
  }
  
  if (data.sttIsWoodpacking) {
    let xRect = 66;
    let xText = 67;

    if (!data.isDangerousGoods) {
      xRect += 11;
      xText += 11;
    }

    if (!data.isReverseJourneyAfterCancel) {
      xRect += 11;
      xText += 11;
    }

    doc.setLineWidth(0.3);
    doc.setFillColor("#333333");
    doc.setDrawColor("#333333");
    doc.roundedRect(coor.startX + xRect, coor.startY + 70, 11, 6, 0.5, 0.5, "FD");
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#FFFFFF");
    doc.setFontSize(9);
    doc.text("KAYU", coor.startX + xText, coor.startY + 74);
  }

  if (data.isReverseJourneyAfterCancel) {
    let xRect = 79;
    let xText = 80;

    if (!data.isDangerousGoods) {
      xRect += 11;
      xText += 11;
    }

    doc.setLineWidth(0.3);
    doc.setFillColor("#333333");
    doc.setDrawColor("#333333");
    doc.roundedRect(coor.startX + xRect, coor.startY + 70, 9, 6, 0.5, 0.5, "FD");
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#FFFFFF");
    doc.setFontSize(9);
    doc.text("CNX", coor.startX + xText, coor.startY + 74);
  }

  if (data.isDangerousGoods) {
    const dgBg = await import("@/app/ui/assets/images/rhombus.jpg");
    doc.setFont("helvetica", "bold");
    doc.addImage(dgBg.default, "JPG", coor.startX + 90, coor.startY + 69, 10, 8, "dgBG", "FAST");
    doc.setTextColor("#FFFFFF");
    doc.setFontSize(8);
    doc.text("DG", coor.startX + 93, coor.startY + 73.8);
  }

  // REFF
  doc.setFont("helvetica", "normal");
  doc.setTextColor("#333333");
  doc.setFontSize(6);
  doc.text(`Ex. Ref : ${params.externalRefValue?.toUpperCase() ?? "-"}`, coor.startX + 100, coor.startY + 82, { align: "right" });

  // BARCODE NINJA
  if (params.vendorRefCodeValue) {
    doc.addImage(params.vendorRefCode, "PNG", coor.startX + 4, coor.startY + 68, 55, 9, uuid(), "FAST", 0);
  }
  doc.setFont("helvetica", "bold");
  doc.setTextColor("#333333");
  doc.setFontSize(6);
  doc.text(params.vendorRefCodeValue, coor.startX + 22, coor.startY + 79);

  // COMPANY
  const elipsisCompanyName = data.sttBookedName.length > 45 ? `${data.sttBookedName.substring(0, 45)}...` : data.sttBookedName;
  doc.setFont("helvetica", "bold");
  doc.setTextColor("#333333");
  doc.setFontSize(6);
  doc.text(titleCase(elipsisCompanyName), coor.startX + 5, coor.startY + 82);

  // LINE MIDDLE
  doc.setLineWidth(0.2);
  doc.line(coor.startX, coor.startY + 85, coor.startX + 105, coor.startY + 85);
}

export const codSection = (
  doc: jsPDF | any,
  coor: { startX: number, startY: number, },
  data: PrintDataEntitas,
  params: { isCod: boolean, isDfod: boolean, isPad: boolean, shipmentAlgo: string, bookingType: string, accountType: string, isPriceOfCOD: boolean, totalBiaya: string, biayaKirim: string, showCodRetail: any, asuransi: string, determinantIsCodRetailTop: number, dfodPrice: string, goodsPrice: string, translate: Function }
) => {
  // COD PRICE
  doc.setFont("helvetica", "bold");
  doc.setTextColor("#333333");
  doc.setFontSize(10);
  if (params.isCod || params.isDfod) {
    doc.text("COD: ", coor.startX + 5, coor.startY + 91);
    doc.text(`${formatPrice(data.sttBookedByCountry, data.sttCodAmount).replace(",", ".")}`, coor.startX + 15, coor.startY + 91);
  } else {
    doc.text("NON COD", coor.startX + 5, coor.startY + 91);
  }

  // PAD PRICE
  if (params.isPad && data.sttProductType !== "INTERPACK") {
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#333333");
    doc.setFontSize(10);
    doc.text(`PAD:`, coor.startX + 5, coor.startY + 95);
    doc.text(`Rp 20.000`, coor.startX + 15, coor.startY + 95);
  }

  // GOOD VALUE FOR INTERPACK
  if (data.sttProductType === "INTERPACK") {
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#333333");
    doc.setFontSize(10);
    doc.text(`${startCase(params.translate("receipt.goodValue"))}`, coor.startX + 5, coor.startY + 95);
    doc.setFont("helvetica", "bold");
    if (data.sttBookedByCountry === "MY") {
      doc.text(formatPrice(data.sttBookedByCountry, data.sttGoodsEstimatePrice), coor.startX + 25, coor.startY + 95);
    } else {
      doc.text(params.translate("receipt.currencyDisplayGoodValue", { value: removeDecimalWithoutRounding(data.sttGoodsEstimatePrice) }), coor.startX + 25, coor.startY + 95);
    }
  }

  // PRICE LEFT
  doc.setFont("helvetica", "normal");
  doc.setTextColor("#333333");
  doc.setFontSize(6);
  doc.text(startCase(params.translate("receipt.totalCost")), coor.startX + 5, coor.startY + 100);
  doc.text(":", coor.startX + 19, coor.startY + 100);
  doc.setFont("helvetica", "bold");
  if (estimationBound(params.shipmentAlgo, params.bookingType, params.accountType, params.isPriceOfCOD)) {
    doc.text("-", coor.startX + 20, coor.startY + 100);
  } else {
    doc.text(params.totalBiaya, coor.startX + 20, coor.startY + 100);
  }

  doc.setFont("helvetica", "normal");
  doc.text(startCase(params.translate("receipt.shippingCost")), coor.startX + 5, coor.startY + (104 - params.determinantIsCodRetailTop));
  doc.text(":", coor.startX + 19, coor.startY + (104 - params.determinantIsCodRetailTop));
  if (estimationBound(params.shipmentAlgo, params.bookingType, params.accountType, params.isPriceOfCOD)) {
    doc.text("-", coor.startX + 20, coor.startY + (104 - params.determinantIsCodRetailTop));
  } else {
    doc.text(params.biayaKirim, coor.startX + 20, coor.startY + (104 - params.determinantIsCodRetailTop));
  }

  const paddingCodRetail = params.showCodRetail || params.isDfod ? 107 : 108;
  doc.text(startCase(params.translate("receipt.insurance")), coor.startX + 5, coor.startY + (paddingCodRetail - params.determinantIsCodRetailTop));
  doc.text(":", coor.startX + 19, coor.startY + (paddingCodRetail - params.determinantIsCodRetailTop));
  if (estimationBound(params.shipmentAlgo, params.bookingType, params.accountType, params.isPriceOfCOD)) {
    doc.text("-", coor.startX + 20, coor.startY + (paddingCodRetail - params.determinantIsCodRetailTop));
  } else {
    doc.text(params.asuransi, coor.startX + 20, coor.startY + (paddingCodRetail - params.determinantIsCodRetailTop));
  }

  if (params.showCodRetail || params.isDfod) {
    doc.text(params.isDfod ? startCase(params.translate("receipt.dfodFee")) : startCase(params.translate("receipt.goodsPrice")), coor.startX + 5, coor.startY + (108 + params.determinantIsCodRetailTop));
    doc.text(":", coor.startX + 19, coor.startY + (108 + params.determinantIsCodRetailTop));
    if (estimationBound(params.shipmentAlgo, params.bookingType, params.accountType, params.isPriceOfCOD)) {
      doc.text("-", coor.startX + 20, coor.startY + (108 + params.determinantIsCodRetailTop));
    } else {
      doc.setFont("helvetica", "bold");
      doc.text(params.isDfod ? params.dfodPrice : params.goodsPrice, coor.startX + 20, coor.startY + (108 + params.determinantIsCodRetailTop));
    }
  }
}

export const topPriceSection = (
  doc: jsPDF | any,
  data: PrintDataEntitas,
  coor: { startX: number, startY: number, },
  params: { totalWeight: string, determinantIsCodRetailTop: number, shipmentAlgo: string, bookingType: string, accountType: string, isPriceOfCOD: boolean, pajak: number, showCodRetail: any, isDfod: boolean, lainLain: number, translate: Function }
) => {
  // PRICE RIGHT
  doc.setFont("helvetica", "normal");
  doc.setTextColor("#333333");
  doc.setFontSize(6);
  doc.text(startCase(params.translate("receipt.totalWeight")), coor.startX + 34, coor.startY + 100);
  doc.text(":", coor.startX + 47, coor.startY + 100);
  doc.setFont("helvetica", "bold");
  doc.text(`${params.totalWeight} kg`, coor.startX + 48, coor.startY + 100);

  doc.setFont("helvetica", "normal");
  doc.text(params.translate("receipt.taxBm"), coor.startX + 34, coor.startY + (104 - params.determinantIsCodRetailTop));
  doc.text(":", coor.startX + 47, coor.startY + (104 - params.determinantIsCodRetailTop));
  if (estimationBound(params.shipmentAlgo, params.bookingType, params.accountType, params.isPriceOfCOD)) {
    doc.text("-", coor.startX + 48, coor.startY + (104 - params.determinantIsCodRetailTop));
  } else {
    doc.text(`${Number(params.pajak) > 0 ? formatPrice(data.sttBookedByCountry, params.pajak) : "-"}`, coor.startX + 48, coor.startY + (104 - params.determinantIsCodRetailTop));
  }

  const otherFeeForCod = params.showCodRetail || params.isDfod ? 1 : 0;
  doc.text(startCase(params.translate("receipt.others")), coor.startX + 34, coor.startY + (108 - otherFeeForCod - params.determinantIsCodRetailTop));
  doc.text(":", coor.startX + 47, coor.startY + (108 - otherFeeForCod - params.determinantIsCodRetailTop));
  if (estimationBound(params.shipmentAlgo, params.bookingType, params.accountType, params.isPriceOfCOD)) {
    doc.text("-", coor.startX + 48, coor.startY + (108 - otherFeeForCod - params.determinantIsCodRetailTop));
  } else {
    doc.text(`${Number(params.lainLain) > 0 ? formatPrice(data.sttBookedByCountry, params.lainLain) : "-"}`, coor.startX + 48, coor.startY + (108 - otherFeeForCod - params.determinantIsCodRetailTop));
  }
}

export const weightVolumeSection = (
  doc: jsPDF | any,
  coor: { startX: number, startY: number, },
  params: { productType: string, pieceData: any, isBigpackVolume: boolean, isJumbopackvolume: boolean, isHubToHub: boolean }
) => {
  // PRODUCT
  doc.setLineWidth(0.3);
  doc.setFillColor("#333333");
  doc.setDrawColor("#333333");
  doc.roundedRect(coor.startX + 52, coor.startY + 87, 26, 8, 0.5, 0.5, "FD");
  doc.setFont("helvetica", "bold");
  doc.setTextColor("#FFFFFF");
  doc.setFontSize(10);
  doc.text(params.productType, coor.startX + productRect(params.productType, false), coor.startY + 92.5);
  doc.text(params.productType, coor.startX + productRect(params.productType, true), coor.startY + 92.5);

  if (params.isHubToHub){
    doc.setTextColor("#333333");
    doc.setFont("helvetica", "bold");
    doc.setFontSize(7);
    doc.text(`Diambil penerima`, coor.startX + productRect(params.productType, false) + 1.5, coor.startY + 97.3);
  }
  // PIECES
  doc.setFont("helvetica", "bold");
  doc.setTextColor("#333333");
  doc.setFontSize(24);
  doc.text(params.pieceData.stt_piece_count, coor.startX + 100, coor.startY + 93, { align: "right" });
  doc.text(params.pieceData.stt_piece_count, coor.startX + 100.1, coor.startY + 93, { align: "right" });

  // GW
  doc.setFont("helvetica", "bold");
  doc.setFontSize(10);
  doc.text("GW", coor.startX + 59, coor.startY + 101);
  doc.text(":", coor.startX + 65, coor.startY + 101);
  doc.setFont("helvetica", "bold");
  doc.setTextColor("#333333");
  doc.setFontSize(10);
  const weights = params.pieceData.stt_piece_weight ? params.pieceData.stt_piece_weight.split("/") : "";
  const GW = params.isBigpackVolume || params.isJumbopackvolume ? "- / -" : `${weights[0]} kg / ${weights[1]} kg`;
  doc.text(GW, coor.startX + 100, coor.startY + 101, { align: "right" });

  // VW
  doc.setFont("helvetica", "bold");
  doc.setFontSize(10);
  doc.text("VW", coor.startX + 59, coor.startY + 109);
  doc.text(":", coor.startX + 65, coor.startY + 109);
  doc.setFont("helvetica", "bold");
  doc.setTextColor("#333333");
  doc.setFontSize(6);
  const volumeWeight = `/ ${removeDecimalWithoutRounding(params.pieceData.stt_piece_volume_weight, 1)} kg`;

  if (params.isBigpackVolume || params.isJumbopackvolume) {
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#333333");
    doc.setFontSize(10);
    doc.text('- / -', coor.startX + 100, coor.startY + 109, { align: "right" });
  } else {
    doc.text(`${removeDecimalWithoutRounding(params.pieceData.stt_piece_length, 1)}/${removeDecimalWithoutRounding(params.pieceData.stt_piece_width, 1)}/${removeDecimalWithoutRounding(params.pieceData.stt_piece_height, 1)} Cm`, coor.startX + dimensionRect(volumeWeight).rectDimension, coor.startY + 109, { align: "right" });
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#333333");
    doc.setFontSize(dimensionRect(volumeWeight).fontVW);
    doc.text(volumeWeight, coor.startX + 100, coor.startY + 109, { align: "right" });
  }
}

export const topInformationSection = async (
  doc: jsPDF | any,
  coor: { startX: number, startY: number, },
  params: { translate: Function }
) => {
  // SUGGEST DOWNLOAD TOP
  doc.setFont("helvetica", "bold");
  doc.setTextColor("#333333");
  doc.setFontSize(4.5);
  doc.text(params.translate("receipt.downloadApps"), coor.startX + 5, coor.startY + 112);

  // LINK LP TOP
  doc.setFont("helvetica", "bold");
  doc.setTextColor("#333333");
  doc.setFontSize(4.5);
  doc.text(params.translate("receipt.toc"), coor.startX + 100, coor.startY + 112, { align: "right" });

  // TEXT CUT
  const cut = await import("@/app/ui/assets/images/black-cut.png");
  doc.setFont("helvetica", "normal");
  doc.addImage(cut.default, "PNG", coor.startX + 5, coor.startY + 114, 2.5, 2.5, "cut", "FAST");
  doc.setFont("helvetica", "bold");
  doc.setTextColor("#333333");
  doc.setFontSize(4.5);
  doc.text(params.translate("receipt.senderPage"), coor.startX + 9, coor.startY + 116);
}

export const detailFooterSection = (
  doc: jsPDF | any,
  coor: { startX: number, startY: number },
  data: PrintDataEntitas,
  params: { logo: string, logoAspectRatio: number, barcode: string, barcodeValue: string, estimateSla: string, translate: Function }
) => {
  // LOGO BOTTOM
  doc.addImage(params.logo, "PNG", coor.startX + 6, coor.startY + 120, 13, params.logoAspectRatio * 13, "logo", "FAST", 0);

  // GENESIS BARCODE BOTTOM
  doc.addImage(params.barcode, "PNG", coor.startX + 19, coor.startY + 117, 38, 8, uuid(), "FAST", 0);
  doc.setFont("helvetica", "bold");
  doc.setTextColor("#333333");
  doc.setFontSize(6);
  doc.text(params.barcodeValue, coor.startX + 28, coor.startY + 127);

  // LABEL CONSIGNE
  doc.setLineWidth(0.3);
  doc.setFillColor(255, 255, 255);
  doc.roundedRect(coor.startX + 5, coor.startY + 117, 95, 28, 1, 1);

  // SHIPER LABEL BOTTOM
  doc.setFont("helvetica", "normal");
  doc.setTextColor("#333333");
  doc.setFontSize(5);
  doc.text(startCase(params.translate("receipt.shipper")), coor.startX + 6, coor.startY + 130);
  doc.text(":", coor.startX + 16, coor.startY + 130);
  doc.setFont("helvetica", "bold");
  const sttSenderNameBottom = data.sttSenderName.length > 70 ? `${data.sttSenderName.substring(0, 70)}...` : data.sttSenderName;
  doc.text(doc.splitTextToSize(`${badWordsMasking(titleCase(sttSenderNameBottom))}`, 40), coor.startX + 17, coor.startY + 130);

  // CONSIGNE LABEL BOTTOM
  doc.setFont("helvetica", "normal");
  doc.setTextColor("#333333");
  doc.setFontSize(5);
  doc.text(startCase(params.translate("receipt.receiver")), coor.startX + 6, coor.startY + 134);
  doc.text(":", coor.startX + 16, coor.startY + 134);
  doc.setFont("helvetica", "bold");
  const sttRecipientNameBottom = data.sttRecipientName.length > 70 ? `${data.sttRecipientName.substring(0, 70)}...` : data.sttRecipientName;
  doc.text(doc.splitTextToSize(`${badWordsMasking(titleCase(sttRecipientNameBottom))}`, 40), coor.startX + 17, coor.startY + 134);

  // DESTINATION LABEL BOTTOM
  doc.setFont("helvetica", "normal");
  doc.setTextColor("#333333");
  doc.setFontSize(5);
  doc.text(startCase(params.translate("receipt.destination")), coor.startX + 6, coor.startY + 138);
  doc.text(":", coor.startX + 16, coor.startY + 138);
  doc.setFont("helvetica", "bold");
  const sttDestinationDistrictNameBottom = data.sttDestinationDistrictName.length >= 40 ? `${data.sttDestinationDistrictName.substring(0, 40)}...` : data.sttDestinationDistrictName;
  doc.text(titleCase(sttDestinationDistrictNameBottom), coor.startX + 17, coor.startY + 138);

  // ESTIMASI BOTTOM
  doc.setFont("helvetica", "normal");
  doc.setTextColor("#333333");
  doc.setFontSize(5);
  doc.text(startCase(params.translate("receipt.estimation")), coor.startX + 6, coor.startY + 141);
  doc.text(":", coor.startX + 16, coor.startY + 141);
  doc.setFont("helvetica", "bold");
  doc.text(titleCase(params.estimateSla), coor.startX + 17, coor.startY + 141);
}

export const bottomInformationSection = (
  doc: jsPDF | any,
  coor: {
    startX: number,
    startY: number
  },
  params: {
    translate: Function
  }
) => {
  // SUGGEST DOWNLOAD TOP
  doc.setFont("helvetica", "bold");
  doc.setTextColor("#333333");
  doc.setFontSize(3.5);
  doc.text(params.translate("receipt.downloadApps"), coor.startX + 6, coor.startY + 144);

  // LINK LP BOTTOM
  doc.setFont("helvetica", "bold");
  doc.setTextColor("#333333");
  doc.setFontSize(3.5);
  doc.text(params.translate("receipt.toc"), coor.startX + 82, coor.startY + 144, {
    align: "right"
  });

  // VERTICAL LINE BOTTOM 1
  doc.setLineWidth(0.3);
  doc.line(coor.startX + 83, coor.startY + 145, coor.startX + 83, coor.startY + 117);

  // VERTICAL LINE BOTTOM 2
  doc.setLineWidth(0.3);
  doc.line(coor.startX + 57, coor.startY + 141, coor.startX + 57, coor.startY + 117);
}

export const productBottomSection = (
  doc: jsPDF | any,
  coor: { startX: number, startY: number, },
  data: PrintDataEntitas,
  params: { isCod: boolean, isPad: boolean, isDfod: boolean, productType: string, language: string, isHubToHub: boolean }
) => {
  // PRODUCT COD PAD BOTTOM
  if (params.isCod && params.isPad) {
    doc.setLineWidth(0.3);
    doc.setFillColor("#333333");
    doc.setDrawColor("#333333");
    doc.roundedRect(coor.startX + 58, coor.startY + 118, 16, 4, 0.5, 0.5, "FD");
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#FFFFFF");
    doc.setFontSize(6);
    doc.text(params.productType, coor.startX + productRectMini(params.productType, "left"), coor.startY + 120.8);
    if (params.isHubToHub){
      doc.setTextColor("#333333");
      doc.setFont("helvetica", "bold");
      doc.setFontSize(5);
      doc.text(`Diambil penerima`, coor.startX + productRectMini(params.productType, "left"), coor.startY + 123.5);
    }

    doc.setLineWidth(0.3);
    doc.setFillColor("#333333");
    doc.setDrawColor("#333333");
    doc.roundedRect(coor.startX + 75, coor.startY + 118, 7, 4, 0.5, 0.5, "FD");
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#FFFFFF");
    doc.setFontSize(6);
    doc.text("COD", coor.startX + 76.5, coor.startY + 120.8);

    doc.setLineWidth(0.3);
    doc.setFillColor("#333333");
    doc.setDrawColor("#333333");
    doc.roundedRect(coor.startX + 75, coor.startY + 123, 7, 4, 0.5, 0.5, "FD");
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#FFFFFF");
    doc.setFontSize(6);
    doc.text("PAD", coor.startX + 76.5, coor.startY + 125.8);
  } else if (notLabeled(params.isCod, params.isPad, params.isDfod)) {
    doc.setLineWidth(0.3);
    doc.setFillColor("#333333");
    doc.setDrawColor("#333333");
    doc.roundedRect(coor.startX + 61.5, coor.startY + 118, 16, 4, 0.5, 0.5, "FD");
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#FFFFFF");
    doc.setFontSize(6);
    doc.text(params.productType, coor.startX + productRectMini(params.productType, "center"), coor.startY + 120.8);
    if (params.isHubToHub){
      doc.setTextColor("#333333");
      doc.setFont("helvetica", "bold");
      doc.setFontSize(5);
      doc.text(`Diambil penerima`, coor.startX + productRectMini(params.productType, "center") - 0.5, coor.startY + 123.5);
    }
  } else {
    doc.setLineWidth(0.3);
    doc.setFillColor("#333333");
    doc.setDrawColor("#333333");
    doc.roundedRect(coor.startX + 58, coor.startY + 118, 16, 4, 0.5, 0.5, "FD");
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#FFFFFF");
    doc.setFontSize(6);
    doc.text(params.productType, coor.startX + productRectMini(params.productType, "left"), coor.startY + 120.8);
    if (params.isHubToHub){
      doc.setTextColor("#333333");
      doc.setFont("helvetica", "bold");
      doc.setFontSize(5);
      doc.text(`Diambil penerima`, coor.startX + productRectMini(params.productType, "left"), coor.startY + 123.5);
    }

    doc.setLineWidth(0.3);
    doc.setFillColor("#333333");
    doc.setDrawColor("#333333");
    doc.roundedRect(coor.startX + 75, coor.startY + 118, 7, 4, 0.5, 0.5, "FD");
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#FFFFFF");
    doc.setFontSize(6);
    doc.text(`${params.isCod ? "COD" : params.isDfod ? "DFOD" : "PAD"}`, coor.startX + (params.isDfod ? 75.5 : 76.5), coor.startY + 120.8);
  }

  // DATE BOTTOM
  doc.setFont("helvetica", "bold");
  doc.setTextColor("#333333");
  doc.setFontSize(6);
  doc.text(
    `${dateToDateString(data.sttCreatedAt, true, false, params.language)}`,
    coor.startX + 58,
    coor.startY + 125.5
  );
}

export const priceBottomSection = (
  doc: jsPDF | any,
  data: PrintDataEntitas,
  coor: { startX: number, startY: number, },
  params: { showCodRetail: any, isDfod: boolean, totalWeight: string, shipmentAlgo: string, bookingType: string, accountType: string, isPriceOfCOD: boolean, biayaKirim: string, asuransi: string, pajak: number, lainLain: number, goodsPrice: string, returnFee: string, dfodPrice: string, codFee: string, translate: Function }
) => {
  // PRICE BOTTOM
  const determinePriceBottom = params.showCodRetail || params.isDfod ? 2.3 : 0;
  doc.setFont("helvetica", "normal");
  doc.setTextColor("#333333");
  doc.setFontSize(5);
  doc.text(startCase(params.translate("receipt.totalWeight")), coor.startX + 58, coor.startY + (130.5 - determinePriceBottom));
  doc.text(":", coor.startX + 70, coor.startY + (130.5 - determinePriceBottom));
  doc.setFont("helvetica", "bold");
  doc.text(`${params.totalWeight} kg`, coor.startX + 71, coor.startY + (130.5 - determinePriceBottom));
  doc.setFont("helvetica", "normal");
  doc.text(startCase(params.translate("receipt.shippingCost")), coor.startX + 58, coor.startY + (132.5 - determinePriceBottom));
  doc.text(":", coor.startX + 70, coor.startY + (132.5 - determinePriceBottom));
  if (estimationBound(params.shipmentAlgo, params.bookingType, params.accountType, params.isPriceOfCOD)) {
    doc.text("-", coor.startX + 71, coor.startY + (132.5 - determinePriceBottom));
  } else {
    doc.text(params.biayaKirim, coor.startX + 71, coor.startY + (132.5 - determinePriceBottom));
  }
  doc.text(startCase(params.translate("receipt.insurance")), coor.startX + 58, coor.startY + (134.5 - determinePriceBottom));
  doc.text(":", coor.startX + 70, coor.startY + (134.5 - determinePriceBottom));

  if (estimationBound(params.shipmentAlgo, params.bookingType, params.accountType, params.isPriceOfCOD)) {
    doc.text("-", coor.startX + 71, coor.startY + (134.5 - determinePriceBottom));
  } else {
    doc.text(params.asuransi, coor.startX + 71, coor.startY + (134.5 - determinePriceBottom));
  }

  doc.text(params.translate("receipt.taxBm"), coor.startX + 58, coor.startY + (136.6 - determinePriceBottom));
  doc.text(":", coor.startX + 70, coor.startY + (136.6 - determinePriceBottom));

  if (estimationBound(params.shipmentAlgo, params.bookingType, params.accountType, params.isPriceOfCOD)) {
    doc.text("-", coor.startX + 71, coor.startY + (136.6 - determinePriceBottom));
  } else {
    doc.text(`${Number(params.pajak) > 0 ? formatPrice(data.sttBookedByCountry, params.pajak) : "-"}`, coor.startX + 71, coor.startY + (136.6 - determinePriceBottom));
  }

  doc.text(startCase(params.translate("receipt.others")), coor.startX + 58, coor.startY + (138.5 - determinePriceBottom));
  doc.text(":", coor.startX + 70, coor.startY + (138.5 - determinePriceBottom));

  if (estimationBound(params.shipmentAlgo, params.bookingType, params.accountType, params.isPriceOfCOD)) {
    doc.text("-", coor.startX + 71, coor.startY + (138.5 - determinePriceBottom));
  } else {
    doc.text(`${Number(params.lainLain) > 0 ? formatPrice(data.sttBookedByCountry, params.lainLain) : "-"}`, coor.startX + 71, coor.startY + (138.5 - determinePriceBottom));
  }
  const determinePriceBottomAdded = params.showCodRetail ? 2 : 0;
  if (params.showCodRetail) {
    doc.text(startCase(params.translate("receipt.goodsPrice")), coor.startX + 58, coor.startY + (138.5 + determinePriceBottomAdded - determinePriceBottom));
    doc.text(":", coor.startX + 70, coor.startY + (138.5 + determinePriceBottomAdded - determinePriceBottom));
    if (estimationBound(params.shipmentAlgo, params.bookingType, params.accountType, params.isPriceOfCOD)) {
      doc.text("-", coor.startX + 71, coor.startY + (138.5 + determinePriceBottomAdded - determinePriceBottom));
    } else {
      doc.text(params.goodsPrice, coor.startX + 71, coor.startY + (138.5 + determinePriceBottomAdded - determinePriceBottom));
    }
  }
  if (params.showCodRetail || params.isDfod) {
    doc.text(startCase(params.translate("receipt.returnFee")), coor.startX + 58, coor.startY + (140.5 + determinePriceBottomAdded - determinePriceBottom));
    doc.text(":", coor.startX + 70, coor.startY + (140.5 + determinePriceBottomAdded - determinePriceBottom));
    if (estimationBound(params.shipmentAlgo, params.bookingType, params.accountType, params.isPriceOfCOD)) {
      doc.text("-", coor.startX + 71, coor.startY + (140.5 + determinePriceBottomAdded - determinePriceBottom));
    } else {
      doc.text(params.returnFee, coor.startX + 71, coor.startY + (140.5 + determinePriceBottomAdded - determinePriceBottom));
    }
    doc.text(params.isDfod ? startCase(params.translate("receipt.dfodFee")) : startCase(params.translate("receipt.codFee")), coor.startX + 58, coor.startY + (142.5 + determinePriceBottomAdded - determinePriceBottom));
    doc.text(":", coor.startX + 70, coor.startY + (142.5 + determinePriceBottomAdded - determinePriceBottom));
    if (estimationBound(params.shipmentAlgo, params.bookingType, params.accountType, params.isPriceOfCOD)) {
      doc.text("-", coor.startX + 71, coor.startY + (142.5 + determinePriceBottomAdded - determinePriceBottom));
    } else {
      doc.text(params.isDfod ? params.dfodPrice : params.codFee, coor.startX + 71, coor.startY + (142.5 + determinePriceBottomAdded - determinePriceBottom));
    }
  }
}

export const QRCodeSection = (
  doc: jsPDF | any,
  coor: { startX: number, startY: number, },
  data: PrintDataEntitas,
  params: { qrcodeValue: string, showCodRetail: any, isDfod: boolean, shipmentAlgo: string, bookingType: string, accountType: string, isPriceOfCOD: boolean, isCod: boolean, translate: Function, lainLain: number }
) => {
  // QR CODE
  doc.addImage(params.qrcodeValue, "PNG", coor.startX + 84, coor.startY + 118, 15, 15, uuid(), "FAST", 0);

  // TOTAL BAYAR BOTTOM
  doc.setFont("helvetica", "normal");
  doc.setTextColor("#333333");
  doc.setFontSize(6);
  doc.text(params.showCodRetail || params.isDfod ? startCase(params.translate("receipt.returnCost")) : startCase(params.translate("receipt.totalCost")), coor.startX + 86, coor.startY + 138);
  doc.setFont("helvetica", "bold");
  const returnCostCod = Number(data.totalRate) + Number(data.sttInsuranceRate) + Number(params.lainLain) + Number(data.sttReturnFee);
  const returnCostDfod = Number(data.totalRate) + Number(params.lainLain) + Number(data.sttReturnFee);
  const returnCostCodDfod = params.showCodRetail  ? returnCostCod : returnCostDfod;
  if (estimationBound(params.shipmentAlgo, params.bookingType, params.accountType, params.isPriceOfCOD)) {
    doc.text("-", coor.startX + 91, coor.startY + 142);
  } else {
    doc.text(params.showCodRetail || params.isDfod ? formatPrice(data.sttBookedByCountry, returnCostCodDfod) : formatPrice(data.sttBookedByCountry, Number(data.totalTariff)), coor.startX + totalBiayaRect(formatPrice(data.sttBookedByCountry, Number(data.totalTariff))).rect, coor.startY + (params.isCod || params.isDfod ? 141.5 : 142));
  }

  if (params.showCodRetail || params.isDfod) {
    // RETUR
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#333333");
    doc.setFontSize(4);
    doc.text(params.translate("receipt.returStatement"), coor.startX + 99, coor.startY + 144, { align: "right" });
  }

  // LINE HORIZONTAL BOTTOM 1
  doc.setLineWidth(0.3);
  doc.line(coor.startX + 5, coor.startY + 128, coor.startX + 57, coor.startY + 128);

  // LINE HORIZONTAL BOTTOM 2
  doc.setLineWidth(0.3);
  doc.line(coor.startX + 83, coor.startY + 134, coor.startX + 100, coor.startY + 134);

  // LINE BOTTOM
  doc.setLineDash([1]);
  doc.line(coor.startX, coor.startY + 114, coor.startX + 104, coor.startY + 114);
}

export const allPrices = (
  data: PrintDataEntitas,
  params: {
    showCodRetail: any,
    isDfod: boolean,
    language: string
  }
) => {
  const totalBiayaBeforeCalculate = params.showCodRetail || params.isDfod
    ? +data.totalTariffCodRetail - data.sttCodFee
    : data.totalTariff;
  const totalBiaya = `${Number(totalBiayaBeforeCalculate) > 0
    ? formatPrice(data.sttBookedByCountry, Number(totalBiayaBeforeCalculate))
    : "-"
    }`;
  const biayaKirim = `${Number(data.totalRate) > 0 ? formatPrice(data.sttBookedByCountry, Number(data.totalRate)) : "-"
    }`;
  const goodsPrice = formatPrice(data.sttBookedByCountry, Number(data.sttGoodsPrice));
  const dfodPrice = formatPrice(data.sttBookedByCountry, Number(data.sttCodFee));
  const asuransi = formatPrice(data.sttBookedByCountry, Number(data.sttInsuranceRate));
  const pajak =
    Number(data.sttBmTaxRate) +
    Number(data.sttPpnTaxRate) +
    Number(data.sttPphTaxRate);
  const lainLain = data.sttIsPromo
    ?
    Number(data.sttWoodpackingAfterRateDiscount) +
    Number(data.sttCommoditySurchargeRateAfterDiscount) +
    Number(data.sttHeavyweightSurchargeRateAfterDiscount) +
    Number(data.sttDocumentSurchargeRateAfterDiscount)
    :
    Number(data.sttWoodpackingRate) +
    Number(data.sttCommoditySurchargeRate) +
    Number(data.sttHeavyweightSurchargeRate) +
    Number(data.sttDocumentSurchargeRate);
  const returnFee = formatPrice(data.sttBookedByCountry, Number(data.sttReturnFee));
  const codFee = formatPrice(data.sttBookedByCountry, Number(data.sttCodFee));
  const isBigpackVolume = data.sttChargeableWeight <= 10 && data.sttProductType.toUpperCase() === "BIGPACK";
  const isJumbopackvolume = data.sttChargeableWeight <= 30 && data.sttProductType.toUpperCase() === "JUMBOPACK";
  const isHubToHub = data.isHubToHub;
  const totalWeight = isBigpackVolume ? 'Min. 10' : isJumbopackvolume ? 'Min. 30' : String(data.sttChargeableWeight);

  const estimateSla = moment(data.estimateSla, "DD MMMM YYYY").isValid()
    ? dateToDateString(
      moment(data.estimateSla, "DD MMMM YYYY"),
      true,
      false,
      params.language
    )
    : data.estimateSla;

  return {
    totalBiaya, biayaKirim, goodsPrice, dfodPrice, asuransi,
    pajak, returnFee, codFee, totalWeight, estimateSla, lainLain, isBigpackVolume, isJumbopackvolume, isHubToHub
  }
}

export const reverseJourneyShipment = (isReverseJourney: boolean, shipmentAlgo: string) => {
  return isReverseJourney && (shipmentAlgo.startsWith(FlagsFeatureShipmentC1.feature_shipment_c1.getValue()) || shipmentAlgo.match(/^AP|^AS/gi));
}

export const variablesDefine = async (
  doc: jsPDF | any,
  data: PrintDataEntitas,
  params: {
    shipmentId: string,
    logo: string,
    bookingType: string,
    accountType: string
  }
) => {
  const logoProperties = doc.getImageProperties(params.logo);
  const logoAspectRatio = logoProperties.height / logoProperties.width;
  const isCod = codDfodLabel(data.sttIsCod, data.sttIsDfod) === "cod";
  const isDfod = codDfodLabel(data.sttIsCod, data.sttIsDfod) === "dfod";
  const watermarkCod = data.sttIsCod;
  const watermarkDfod = data.sttIsDfod;

  let shipmentAlgo = params.shipmentId ? params.shipmentId : "";
  let isPriceOfCOD = isCod;

  if (reverseJourneyShipment(data.isReverseJourney, shipmentAlgo)) {
    shipmentAlgo = data.sttReverseJourneyShipmentId;
    isPriceOfCOD = data.sttReverseJourneyCodHandling;
  }

  const externalRefValue = externalRefSection(titleCase(data.sttNoRefExternal))
    .externalRef;
  const productType = data.sttProductType;
  const combineQRTextForRegular = `https://lionparcel.com/track/stt?q=${data.sttNo}`;

  const combineQRTextForCourirRecomendation = JSON.stringify({
    stt: data.sttNo,
    partner_name: data.sttBookedForName,
    booking_id: data.otherShipperTicketCode
  });

  const ursaCode = `${abbreviationVendorCode(
    data.sttVendorName,
    data.sttDistrictType
  )}${data.sttDistrictUrsaCode}`;

  let qrcodeValue = "";
  if (isCourierRecommendation(data.sttNoRefExternal)) {
    qrcodeValue = await convertQRCode(combineQRTextForCourirRecomendation);
  } else {
    qrcodeValue = await convertQRCode(combineQRTextForRegular);
  }

  const showCodRetail =
    isCodRetail(params.bookingType, params.accountType, shipmentAlgo, isPriceOfCOD) ||
    data.sttShipmentId.match(/^ARA|^ARB/gi);
  const determinantIsCodRetailTop = showCodRetail || isDfod ? 1 : 0;
  const isPad = false;

  return {
    logoAspectRatio, isCod, isDfod, shipmentAlgo, isPriceOfCOD, externalRefValue,
    productType, ursaCode, qrcodeValue, showCodRetail, determinantIsCodRetailTop, isPad, 
    watermarkCod, watermarkDfod
  }
}
